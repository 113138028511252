import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Input,
  Select,
  Table,
  Dropdown,
  Pagination,
  DatePicker,
  Form,
  Modal,
  Button,
  Tooltip,
} from "antd";
import "./chien-dich.scss";
import { apiCampaign } from "api/campaign";
import { useSelector } from "react-redux";
import { reverseDate, formatId } from "utils/utils-format";

import { DuyetChienDichModal } from "components/duyet-chien-dich-modal/duyet-chien-dich-modal";
import { ThemChienDichModal } from "components/them-chien-dich-modal/them-chien-dich-modal";
import { GetCodeModal } from "components/get-code-modal/get-code-modal";
import { InputAutoComplete } from "components/input-autocomplete/input-autocomplete";
import { SelectAgency } from "components/select-dai-ly/select-dai-ly";

import IconCheckCircleGray from "assets/icons/icon-check-circle-gray.svg";
import IconPlusFill from "assets/icons/icon-plus-fill.svg";
import IconVerticalDots from "assets/icons/icon-vertical-dots.svg";
import IconStop from "assets/icons/icon-stop.svg";
import IconPlay from "assets/icons/icon-play.svg";
import IconFilter from "assets/icons/icon-filter.svg";
import IconRefresh from "assets/icons/icon-refresh.svg";
import IconCoding from "assets/icons/icon-coding.svg";
import IconUpgrade from "assets/icons/icon-upgrade.svg";
import IconDowngrade from "assets/icons/icon-downgrade.svg";
import IconCancel from "assets/icons/icon-cancel.svg";
import IconTrash from "assets/icons/icon-trash.svg";
import { showMessage } from "utils/utils-show-message";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const ChienDich = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalCode, setVisibleModalCode] = useState(false);
  const [visibleModalData, setVisibleModalData] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPage, setTotalPage] = useState(10);
  const [dataCampaign, setDataCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [domainUrl, setDomainUrl] = useState("");

  const [state, setState] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchMethod, setSearchMethod] = useState("tenMien");
  const [isFilter, setIsFilter] = useState(false);
  const [url, setUrl] = useState("");
  const [accountName, setAccountName] = useState("");
  const [scriptCode, setScriptCode] = useState("");
  const [widgetCode, setWidgetCode] = useState("");
  const [suggestionAccount, setSuggestionAccount] = useState([]);
  const [suggestionDomain, setSuggestionDomain] = useState([]);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [recordSelected, setRecordSelected] = useState(null);
  const [agencyIdSelected, setAgencyIdSelected] = useState(0);

  const roleIdUser = useSelector((state) => state.account.role);

  const actionTableDropdown = (value, row, index) => {
    return (
      <div className="account-dropdown">
        {row.trangThai === "INACTIVE" && (
          <div
            key="1"
            className="dropdown-item d-flex align-items-center"
            onClick={() => activeCampaign(row)}
          >
            <img src={IconPlay} alt="" />
            Mở chiến dịch
          </div>
        )}
        {row.trangThai === "ACTIVE" && (
          <div
            key="2"
            className="dropdown-item d-flex align-items-center"
            onClick={() => disableCampaign(row)}
          >
            <img src={IconStop} alt="" />
            Dừng chiến dịch
          </div>
        )}
        <div
          key="3"
          className="dropdown-item d-flex align-items-center"
          onClick={() => {
            setWidgetCode(row.widgetCode);
            setScriptCode(row.scriptCode);
            setVisibleModalCode(true);
          }}
        >
          <img src={IconCoding} alt="" />
          Lấy mã nhúng
        </div>
        {["ADMIN", "ROOT_ADMIN"].includes(roleIdUser) &&
          row.stateUnLimit === "pending" && (
            <div
              key="4"
              className="dropdown-item d-flex align-items-center"
              onClick={() => {
                setDomainUrl(row.chienDich);
                setVisibleModalData(true);
              }}
            >
              <img src={IconCheckCircleGray} alt="" />
              Duyệt
            </div>
          )}
        {/*{row.stateUnLimit === "limit" && (*/}
        {/*  <div*/}
        {/*    key="5"*/}
        {/*    className="dropdown-item d-flex align-items-center"*/}
        {/*    onClick={() => upgradeCampaign(row.chienDich)}*/}
        {/*  >*/}
        {/*    <img src={IconUpgrade} alt="" />*/}
        {/*    Nâng cấp*/}
        {/*  </div>*/}
        {/*)}*/}
        {["ADMIN", "ROOT_ADMIN"].includes(roleIdUser) &&
          row.stateUnLimit === "pending" && (
            <div
              key="5"
              className="dropdown-item d-flex align-items-center"
              onClick={() => unLimitRollback(row.chienDich)}
            >
              <img src={IconCancel} alt="" />
              Hủy duyệt
            </div>
          )}
        {row.stateUnLimit === "approve" && (
          <div
            key="5"
            className="dropdown-item d-flex align-items-center"
            onClick={() => backToCampaignLimit(row.chienDich)}
          >
            <img src={IconDowngrade} alt="" />
            Chuyển về gói thường
          </div>
        )}
        <div
          key="6"
          className="dropdown-item d-flex align-items-center"
          onClick={() => openConfirmDelete(row)}
        >
          <img src={IconTrash} alt="" />
          Xóa chiến dịch
        </div>
      </div>
    );
  };

  const columnsCampaign = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 150,
    },
    {
      title: "Chiến dịch",
      dataIndex: "chienDich",
      key: "chienDich",
      // className: "width-150-px-in-1200-column",
      ellipsis: true,
      width: 300,
      render: (value) => <Tooltip title={value}>{value}</Tooltip>,
    },
    {
      title: ["ROOT_ADMIN", "ADMIN", "AGENCY", "SALE"].includes(roleIdUser)
        ? "Tài khoản"
        : "",
      dataIndex: "taiKhoan",
      key: "taiKhoan",
      className: "width-150-px",
    },
    {
      title: "Sản lượng",
      dataIndex: "sanLuong",
      key: "sanLuong",
      className: "width-125-px",
    },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      className: "width-150-px",
      render: (value, row, index) => {
        return (
          <div className="d-flex">
            {row.trangThai === "ACTIVE" && (
              <span className="badge mr-1 badge-success">Hoạt động</span>
            )}
            {row.trangThai === "INACTIVE" && (
              <span className="badge mr-1 bg-soft-danger text-danger">
                Tạm dừng
              </span>
            )}
            {row.stateUnLimit === "pending" && (
              <span className="badge mr-1 badge-warning">Chờ duyệt</span>
            )}
            {row.stateUnLimit === "approve" && (
              <span className="badge mr-1 badge-primary">Đã duyệt</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      key: "ngayTao",
      className: "width-125-px",
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "ngayHetHan",
      key: "ngayHetHan",
      className: "width-125-px",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (value, row, index) => (
        <Dropdown
          overlay={actionTableDropdown(value, row, index)}
          trigger={["click"]}
          placement="bottomRight"
          className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-sm"
        >
          <div className="table-action-icon">
            <img width="13" src={IconVerticalDots} alt="" />
          </div>
        </Dropdown>
      ),
    },
  ];

  const renderPlaceholder = () => {
    switch (searchMethod) {
      case "tenMien":
        return "Nhập Tên miền cần tìm";
      case "accountName":
        return "Nhập Tên Tài khoản cần tìm";
      default:
        return "";
    }
  };

  const activeCampaign = async (target) => {
    const res = await apiCampaign.activeStatus(target.chienDichId);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Kích hoạt chiến dịch thành công");
      getAllCampaign();
    } else {
      showMessage(
        "error",
        res?.status?.message === undefined ? res?.message : res?.status?.message
      );
    }
  };

  const disableCampaign = async (target) => {
    const res = await apiCampaign.inactiveStatus(target.chienDichId);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Dừng chiến dịch thành công");
      getAllCampaign();
    } else {
      showMessage(
        "error",
        res?.status?.message === undefined ? res?.message : res?.status?.message
      );
    }
  };

  const upgradeCampaign = async (url) => {
    const res = await apiCampaign.upgradeCampaign(url);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Nâng cấp chiến dịch thành công");
      getAllCampaign();
    } else {
      showMessage(
        "error",
        res?.status?.message === undefined ? res?.message : res?.status?.message
      );
    }
  };

  const backToCampaignLimit = async (url) => {
    const res = await apiCampaign.backToCampaignLimit(url, null, null);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Chuyển về gói thường thành công");
      getAllCampaign();
    } else {
      showMessage(
        "error",
        res?.status?.message === undefined ? res?.message : res?.status?.message
      );
    }
  };

  const unLimitRollback = async (url) => {
    const res = await apiCampaign.unLimitRollback(url);
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Hủy duyệt chiến dịch thành công");
      getAllCampaign();
    } else {
      showMessage(
        "error",
        res?.status?.message === undefined ? res?.message : res?.status?.message
      );
    }
  };

  const closeModal = () => {
    setVisibleModalCode(false);
    setScriptCode("");
  };

  const resetSearchValue = () => {
    setUrl("");
    setAccountName("");
  };

  const checkIsFilter = () => {
    if (
      url !== "" ||
      searchMethod !== "tenMien" ||
      fromDate !== "" ||
      toDate !== "" ||
      state !== ""
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  };

  const deleteFilter = () => {
    setUrl("");
    setState("");
    setFromDate("");
    setToDate("");
    setAccountName("");
    setPageIndex(1);
    setIsFilter(false);
    setSearchMethod("tenMien");
    getAllCampaign();
  };

  const openConfirmDelete = (row) => {
    setVisibleModalDelete(true);
    setRecordSelected(row);
  };

  const deleteCampaign = async () => {
    const res = await apiCampaign.deleteAdminCampaign(
      recordSelected.chienDichId
    );
    if (res?.status?.code === "SUCCESS") {
      showMessage("success", "Xóa chiến dịch thành công");
      setVisibleModalDelete(false);
      setRecordSelected(null);
      getAllCampaign();
    } else {
      showMessage(
        "error",
        res?.status?.message === undefined ? res?.message : res?.status?.message
      );
    }
  };

  const handleDatePicker = (date, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setPageIndex(1);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const getAllSuggestion = async () => {
    const res = await apiCampaign.getAll({
      pageIndex: 1,
      pageSize: 100,
      agencyId: agencyIdSelected,
    });
    if (res?.status?.code === "SUCCESS") {
      const data = res.data;
      const suggestionDomain = [];
      const suggestionAccount = [];
      data.forEach((item) => {
        suggestionDomain.push(item.url);
        suggestionAccount.push(item.user.userName);
      });
      setSuggestionDomain(suggestionDomain);
      setSuggestionAccount(suggestionAccount);
    }
  };

  const renderSuggestion = () => {
    if (searchMethod === "tenMien") {
      return suggestionDomain;
    }
    if (searchMethod === "accountName") {
      return suggestionAccount;
    }
    return [];
  };

  useEffect(() => {
    getAllSuggestion();
  }, []);

  useEffect(() => {
    getAllCampaign();
  }, [url, accountName, agencyIdSelected]);

  const onSearch = (value) => {
    setPageIndex(1);
    if (searchMethod === "tenMien") {
      setAccountName("");
      setUrl(value);
    }
    if (searchMethod === "accountName") {
      setUrl("");
      setAccountName(value);
    }
  };

  useEffect(() => {
    getAllCampaign();
  }, [pageIndex, pageSize, state, toDate]);

  useEffect(() => {
    renderPlaceholder();
    checkIsFilter();
  }, [searchMethod, isFilter]);

  const getAllCampaign = async () => {
    checkIsFilter();
    setLoading(true);
    const res = await apiCampaign.getAll({
      pageIndex,
      pageSize,
      state,
      url,
      fromDate,
      toDate,
      userName: accountName,
      agencyId: agencyIdSelected,
    });
    setLoading(false);
    if (res?.status?.code === "SUCCESS") {
      const data = [];
      res.data.forEach((item, index) => {
        const createdDate = new Date(reverseDate(item.createdDate));
        const record = {
          id: formatId(createdDate, item.id),
          chienDichId: item.id,
          chienDich: item.url,
          sanLuong:
            item.stateUnLimit !== "limit"
              ? "Không giới hạn"
              : `${item.usedData} / ${item.totalData}`,
          trangThai: item.state,
          stateUnLimit: item.stateUnLimit,
          ngayTao: item.createdDate?.slice(0, 10),
          ngayHetHan: item.expireDate?.slice(0, 10),
          scriptCode: item.scriptCode,
          widgetCode: item.widgetSrc,
        };
        if (["ROOT_ADMIN", "ADMIN", "AGENCY", "SALE"].includes(roleIdUser))
          record.taiKhoan = item.user.userName;
        data.push(record);
      });
      setDataCampaign(data);
      setTotalPage(res.totalItem);
    }
  };

  const handleAgencyChange = (agencyId) => {
    setAgencyIdSelected(agencyId);
  };

  return (
    <div className="chien-dich-page position-relative min-height-100vh">
      <DuyetChienDichModal
        visibleModal={visibleModalData}
        domainUrl={domainUrl}
        onSuccess={() => {
          setVisibleModalData(false);
          setDomainUrl("");
          getAllCampaign();
        }}
        onCancel={() => {
          setVisibleModalData(false);
        }}
      />

      <ThemChienDichModal
        visibleModal={visibleModal}
        onCancel={() => setVisibleModal(false)}
        onSuccess={() => getAllCampaign()}
      />
      <GetCodeModal
        visible={visibleModalCode}
        scriptCode={scriptCode}
        widgetCode={widgetCode}
        onCancel={() => closeModal()}
      />
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <div className="page-title-right">
                <Form className="form-inline">
                  <button
                    className="btn btn-secondary btn-sm ml-2"
                    onClick={() => {
                      resetSearchValue();
                      setFromDate("");
                      setToDate("");
                      getAllCampaign();
                    }}
                  >
                    <img src={IconRefresh} alt="" width="11" />
                  </button>
                </Form>
              </div>
              <h4 className="page-title">Chiến dịch</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card-box">
              <h4 className="header-title d-flex align-items-center flex-wrap justify-content-between">
                <span className="mb-2">Danh sách chiến dịch</span>
                <div className="d-flex flex-wrap align-items-center">
                  {isFilter && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger waves-effect waves-light mb-2 mr-2 d-flex align-items-center"
                      onClick={() => deleteFilter()}
                    >
                      <img
                        src={IconFilter}
                        width="12"
                        className="mr-1"
                        alt=""
                      />{" "}
                      Xoá bộ lọc
                    </button>
                  )}
                  <button
                    type="button"
                    className="btn btn-sm btn-blue waves-effect mb-2 waves-light float-right d-flex align-items-center"
                    onClick={() => setVisibleModal(true)}
                  >
                    <img
                      src={IconPlusFill}
                      width="12"
                      className="mr-1"
                      alt=""
                    />{" "}
                    Tạo chiến dịch
                  </button>
                </div>
              </h4>

              {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                  <div className="tool-item filter d-flex align-items-center">
                    <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                      Đại lý:
                      <SelectAgency
                        placeholder="Tất cả"
                        onChange={(value) => handleAgencyChange(value)}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="table-tools d-flex align-items-center justify-content-between mb-3">
                <div className="tool-item filter d-flex align-items-center">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select">
                    Kiểu tìm kiếm
                    <Select
                      value={searchMethod}
                      onSelect={(value) => {
                        resetSearchValue();
                        setSearchMethod(value);
                      }}
                    >
                      <Option value="tenMien">Tên miền</Option>
                      {["ROOT_ADMIN", "ADMIN"].includes(roleIdUser) && (
                        <Option value="accountName">Tài khoản</Option>
                      )}
                    </Select>
                  </div>
                  <div className="tool-item search d-flex align-items-center text-nowrap">
                    Tìm kiếm:
                    <InputAutoComplete
                      isFilter={isFilter}
                      dataSource={renderSuggestion()}
                      placeholder={renderPlaceholder()}
                      onSearch={(value) => onSearch(value)}
                    />
                  </div>
                </div>
                <div className="tool-item flex-wrap d-flex align-items-center text-nowrap">
                  <div className="tool-item search d-flex align-items-center text-nowrap search-method-select mr-2">
                    Trạng thái
                    <Select
                      value={state}
                      onSelect={(value) => {
                        setState(value);
                      }}
                    >
                      <Option value="">Tất cả</Option>
                      <Option value="ACTIVE">Hoạt động</Option>
                      <Option value="INACTIVE">Tạm dừng</Option>
                    </Select>
                  </div>
                  <RangePicker
                    className="tool-item search"
                    format="DD-MM-YYYY"
                    value={[
                      fromDate ? moment(reverseDate(fromDate)) : null,
                      toDate ? moment(reverseDate(toDate)) : null,
                    ]}
                    placeholder={["Từ ngày", "Đến ngày"]}
                    onChange={(date, dateString) =>
                      handleDatePicker(date, dateString)
                    }
                  />
                </div>
              </div>

              <Table
                loading={loading}
                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                dataSource={dataCampaign}
                columns={columnsCampaign}
                pagination={false}
              />

              {dataCampaign.length !== 0 && (
                <div className="table-panigate d-flex align-items-center justify-content-between mt-3">
                  <div className="dataTables_info">
                    Trang {pageIndex} trong tổng số{" "}
                    {Math.ceil(totalPage / pageSize)} trang
                  </div>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="tool-item select d-flex align-items-center pagesize-select">
                      Hiển thị
                      <Select
                        value={pageSize}
                        onSelect={(value) => setPageSize(value)}
                      >
                        <Option value="10">10</Option>
                        <Option value="30">30</Option>
                        <Option value="50">50</Option>
                        <Option value="70">70</Option>
                        <Option value="100">100</Option>
                      </Select>
                      dữ liệu
                    </div>
                    <div className="table-pagination">
                      <Pagination
                        current={pageIndex}
                        pageSize={pageSize}
                        total={totalPage}
                        showSizeChanger={false}
                        onChange={(page) => setPageIndex(page)}
                        hideOnSinglePage={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
      <Modal
        title={"Xác nhận xóa chiến dịch?"}
        footer={null}
        visible={visibleModalDelete}
        onOk={() => deleteCampaign()}
        okText={"Xác nhận"}
        cancelText={"Hủy"}
        onCancel={() => {
          setVisibleModalDelete(false);
          setRecordSelected(null);
        }}
        wrapClassName="them-chien-dich-modal them-don-hang-modal them-user-modal"
      >
        <p>{"Bạn có chắc chắn muốn xóa chiến dịch này không?"}</p>
        <div className="btn-step-group d-flex justify-content-end">
          <Button
            className="btn btn-default btn-block"
            type="button"
            onClick={() => {
              setVisibleModalDelete(false);
              setRecordSelected(null);
            }}
          >
            {" Hủy "}
          </Button>
          <Button
            className="btn btn-danger btn-block"
            type="button"
            onClick={() => deleteCampaign()}
          >
            {" Xác nhận "}
          </Button>
        </div>
      </Modal>
    </div>
  );
};
